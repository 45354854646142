import React from "react";
export const Image = ({ title, largeImage, smallImage, url1 }) => {
  return (
    <div className="portfolio-item">
      <div className="hover-bg">
        {" "}
        <a href={url1} title={title} data-lightbox-gallery="gallery1" target="_blank" style={{display:'flex', flexWrap:'wrap',gap:'5em'}}>
          <div className="hover-text">
            <h4>View More</h4>
          </div>
          <img src={smallImage} className="img-responsive" alt={title} style={{borderBottomLeftRadius: 15,
            borderBottomRightRadius: 15,
            borderTopRightRadius: 15,
            borderTopLeftRadius: 15,
            minHeight:'150px',
            width:'300px',
            maxHeight:'150px',
            margin:'.8em'
          }}/>
          {" "}
        </a>{" "}
      </div>
    </div>
  );
};
