import React, { useState, useEffect } from "react";
import { Navigation } from "./navigation";
import { Header } from "./header";
import { Features } from "./features";
import { About } from "./about";
import { Services } from "./services";
import { Event } from "./eventpage";
import { Gallery } from "./gallery";
import { Testimonials } from "./testimonials";
import { Team } from "./Team";
import { Contact } from "./contact";
import JsonData from "../data/data.json";
import SmoothScroll from "smooth-scroll";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import Carousel1 from "./Carousel";
import { Schedule } from "./schedule";
import { Sponsors } from "./sponsors";

import Comingpage from ".//coming";
import { PrevSponsors } from "./PrevSponsors";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const SponsorsPage = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  const myStyle={ 
    width:'20%', 
    height:'100px', 
    }; 

  return (
    <div className="backgorund-particle">
      <Navigation />
      <PrevSponsors data={landingPageData.PrevSponsors}/>
      {/* <Sponsors data={landingPageData.Sponsors} /> */}
      <Carousel1 data = {landingPageData.slidern}/>
      {/* <Team data={landingPageData.Team} /> */}
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default SponsorsPage;
