import React from "react";
import { Carousel } from "react-responsive-carousel";
import "./carousel.css";
 export default function Carousel1({data}) {
    return (
        <div className="gallary-image">
        <div className="container">
            <div className="section-title">
                <h2>Gallery</h2>
            </div>
            <Carousel className="crsl" autoPlay
                infiniteLoop
                centerMode
                centerSlidePercentage={100}
                interval={1000} showThumbs={false} >
               {
                data?.map((item,index)=>
                        <div key = {index}>
                            <img src={item.img} alt='nothing' />
                        </div>
                )
               }
            </Carousel>

        </div>

        </div>
    )

}



