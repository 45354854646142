import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <a
                  href="#about"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Know More
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    // <div id="header">
    //   <img src="img/imgbg.gif" className="hero-image" height="40vh" />
    //   <div className="col-md-8 col-md-offset-2 intro-text">
    //     <h1>
    //       {props.data ? props.data.title : "Loading"}
    //       <span></span>
    //     </h1>
    //     <p>{props.data ? props.data.paragraph : "Loading"}</p>
    //     <a
    //       href="#features"
    //       className="btn btn-custom btn-lg page-scroll"
    //     >
    //       Know More
    //     </a>{" "}
    //   </div>
    // </div>
  );
};
