import React from 'react';

function ErrorPage() {
  return (
    <div className="Coming">
    <div className="Coming-container" >
    <h3 className="hcoming">
        404 Error
        <br />
        Something is missing!
      </h3>
    </div>
  </div>
  );
}

export default ErrorPage;
