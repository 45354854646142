import React from "react";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

export const Schedule = (props) => {
  return (
    <div id="schedules" className="text-center">
      <div className="container">
      <div className="section-title">
          <h2>Schedule</h2>
          <p>
           Event schedule of Abhyudaya 2024 are:
          </p>
        </div>
      <VerticalTimeline>
  
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
  >
    <h3 className="vertical-timeline-element-title">Day 1 (1st February 2024) </h3>
    <h4 className="vertical-timeline-element-subtitle">Industrial Conclave  Time (10:00 to 1:00pm)</h4>
    <h4 className="vertical-timeline-element-subtitle">Lunch  Time (1:00 to 2:00pm)</h4>
    <h4 className="vertical-timeline-element-subtitle">Northeast Got Talents & Felicitation of Successful Entrepreneurs  Time (2:00 to 3:00pm)</h4>
    <h4 className="vertical-timeline-element-subtitle">Business Idea Competition  Time(3:00 to 5:00pm)</h4>
    <p className="vertical-timeline-element-desc" style={{fontSize:'1em'}}>
      Venue: Bipin Chandra paul Auditorium
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
  >
     <h3 className="vertical-timeline-element-title">Day 2 (2nd February 2024) </h3>
    <h4 className="vertical-timeline-element-subtitle">Debate  Time (10:00 to 12:30pm)</h4>
    <h4 className="vertical-timeline-element-subtitle">Lunch  Time (12:30 to 1:30pm)</h4>
    <h4 className="vertical-timeline-element-subtitle">Advertisement Competition  Time (1:30 to 3:30pm)</h4>
    <h4 className="vertical-timeline-element-subtitle">Quiz Competition  Time (1:30 to 6:30pm)</h4>
    <p className="vertical-timeline-element-desc" style={{fontSize:'1em'}}>
      Venue: Bipin Chandra paul Auditorium, and Conference Hall
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
  >
       <h3 className="vertical-timeline-element-title">Day 3 (3rd February 2024) </h3>
    <h4 className="vertical-timeline-element-subtitle">Alumni Meet  Time (10:00 to 12:30pm)</h4>
    <h4 className="vertical-timeline-element-subtitle">Lunch  Time (12:30 to 1:30pm)</h4>
    <h4 className="vertical-timeline-element-subtitle">Singing and Dancing Competition  Time (1:30 to 4:30pm)</h4>
    <h4 className="vertical-timeline-element-subtitle">Cultural Evening  Time (5:30 to 9:30pm)</h4>
    <p className="vertical-timeline-element-desc" style={{fontSize:'1em'}}>
      Venue: Bipin Chandra paul Auditorium  and  Muktha Munch
    </p>
  </VerticalTimelineElement>
</VerticalTimeline>
      </div>
    </div>
  );
};
