import React from "react";
import { Image } from "./image";
import './image.css'

export const Merchandise = (props) => {
  return (
    <div id="merchandise" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Merchandise</h2>
          <p>
           Order the exciting exclusive merchandise of abhyudaya 2024
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items" style={{display:'flex',justifyContent:'center'}}>
                  <a href="https://linktr.ee/abhyudayafest" target="blank">
                    <div className="card">
                    <div className="card-body">
                    <img src="./img/mercendise.jpg" alt="official Mercendise"  className='merchn'/>
                <h5>Order Now</h5>
                    </div>
                    </div>
                  </a>
                  
          </div>
        </div>
      </div>
    </div>
  );
};
 